function toggleOverlapClass(){
    var overlapped_coor = jQuery('#main-header .logo-container')[0].getBoundingClientRect();
    var overlapped_position = overlapped_coor.top + jQuery('#main-header .logo-container').height(); // get top offset + element height
    var overlapping_coor = jQuery('.main-menu .menu')[0].getBoundingClientRect();
    
    if(overlapping_coor.top < overlapped_position){
        jQuery('body').addClass('menu-overlap');
    }else{
        jQuery('body').removeClass('menu-overlap');
    }
}

function initMobileMenu() {
    var $menu_button = jQuery('.main-header .menu-button');
    var $menu = jQuery('.main-menu-nav');
    var $menu_inner = jQuery('.main-menu-nav .main-menu-inner');
    var $body = jQuery('body');

    $menu_button.attr('data-button-text', $menu_button.text());

    jQuery(document).on('click', '.main-header .menu-button', function (e) {
        e.preventDefault();

        if (!$body.hasClass('menu-active')) {
            $body.addClass('menu-active').delay(200)
                    .queue(function (next) {
                        $menu.addClass('active');
                        next();
                    }).delay(400)
                    .queue(function () {
                        $menu_inner.addClass('active').dequeue();
                        $body.addClass('close-button');
                    });

            jQuery(this).find('.button-text').text(jQuery(this).attr('data-close-text'));
        } else {
            $menu_inner.removeClass('active').delay(400)
                    .queue(function (next) {
                        $menu.removeClass('active');
                        $body.removeClass('close-button');
                        next();
                    }).delay(200)
                    .queue(function () {
                        $body.removeClass('menu-active').dequeue();
                    });

            jQuery(this).find('.button-text').text(jQuery(this).attr('data-button-text'));
        }

    });
    
    var menuScrollTimer;
    jQuery('.main-menu-nav').on('scroll', function (){
        menuScrollTimer = setTimeout(function () {
            toggleOverlapClass();
        }, 100);
    });
}

function bannerTitleSep(){
    jQuery('.has-hero .main-hero .banner-title, .has-hero .main-hero .banner-content').addClass('show');
    jQuery('.has-hero .main-hero .banner-title span').each(function (index) {
        var $_this = jQuery(this);
        setTimeout(function () {
            $_this.addClass('show');
        }, 30 * index);
    });
}

function onHeroLoadAnimation() {
    if (jQuery('.has-hero').length && hero.has_hero == 1) {

        jQuery('.has-hero .main-hero .banner-title').html(function (index, html) {
            var title = document.createElement("textarea");
            title.innerHTML = html;
            return title.value.replace(/\S/g, '<span>$&</span>');
        }).addClass('sep');
        
        if(hero.hero_img){
            //Wait for background image to finish loading before adding class for animation
            jQuery('<img>').attr('src', hero_img.url).load(function () {
                bannerTitleSep();
            });
        }else{
            bannerTitleSep();
        }
    }
}

function setMobileAccordion(accordion_body_selector_class, btn_text) {
    var $accordion_body = jQuery(accordion_body_selector_class);

    if ($accordion_body.length) {
        var accordion_button = '<span class="accordion-btn">' + btn_text + '</span>';

        $accordion_body.addClass('accordion-body ' + accordion_body_selector_class.replace('.', 'accordion-body-'));
        jQuery(accordion_button).insertBefore($accordion_body);

        jQuery(document).on('click', '.accordion-btn', function (e) {
            jQuery(this).toggleClass('show-body');
            jQuery(this).next('.accordion-body').toggleClass('toggled');
        });
    }
}
//Move to separate js and load when needed
function accordionItemsInit() {
    if (jQuery('.accordion-items').length) {
        jQuery('.accordion-items').addClass('active');
        jQuery('.accordion-items').on('click', '.toggle-button', function (e) {
            e.preventDefault();
            var $closest_item = jQuery(this).closest('.accordion-item');
            if($closest_item.hasClass('show-content')){
                $closest_item.removeClass('show-content');
            }else{
                jQuery('.accordion-item').removeClass('show-content');
                $closest_item.toggleClass('show-content');
            }
            
            
        });
    }
}

jQuery(document).ready(function ($) {
    initMobileMenu();
    //onHeroLoadAnimation(); 
    setMobileAccordion('.project-types-nav', 'categories');
    accordionItemsInit();

    $('.main-menu').on('click', '.menu .menu-item-has-children', function (e) {
        e.stopPropagation();
        $(this).toggleClass('show-submenu');
    });

    if (typeof $(".gallery-item").magnificPopup !== "undefined") {
        $(".gallery-item").magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            },
            callbacks:{
                open: function(){
                    if(!$('.close-popup').length){
                        $('.mfp-wrap').append('<span class="close-popup"></span>');
                    }
                }
            }
        });
    }
    
  // Select all links with hashes
    $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('.project-type-link')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
        && 
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function() {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            };
          });
        }
      }
    });
});